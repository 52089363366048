const getCachedFiles = async () => {
    try {
        const cache = await caches.open('v1')
        const keys = await cache.keys()
        const urls = keys.map(request => request.url);
        for (let i = 0; i < urls.length; i++) {
            urls[i] = urls[i].replace(/.+library\.org/, '')
        }
        console.log('URLs from getCachedFiles function', urls);
        return urls
    } catch {}
}

export default getCachedFiles