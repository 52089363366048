import limitedAvailabilityDetector from "./limitedAvailabilityDetector";
import parseVarfields from "./parseVarfields";

const availabilityString = (data) => {
  try {
    if (data.eprovider === "OverDrive, Inc.") {
      if (data.digital_copies_available === 0) {
        if (data.digital_holds === 0) {
          return data.digital_copies_owned + (data.digital_copies_owned === 1 ? ' copy': ' copies');
        }
        
        return data.digital_holds + (data.digital_holds === 1 ? ' hold, ': ' holds, ') + data.digital_copies_owned + (data.digital_copies_owned === 1 ? ' copy': ' copies');
      } else {
        if (data.digital_copies_available === 1) {
          return data.digital_copies_available + " copy available now";
        } else {
          if (data.digital_avail_type === 'AlwaysAvailable') {
            return 'Unlimited copies';
          }
          return data.digital_copies_available + " copies available now";
        }
      }
    } else if (data.eprovider === "Online Resource") {
      let resourceProviders = '';
      const varFields = parseVarfields(data.sm_bib_varfields);
      try {
        for (let i = 0; i < varFields.length; i++) {
          try {
            if (varFields[i].tag === '710') {
              for (let x = 0; x < varFields[i].subfields.length; x++) {
                resourceProviders += varFields[i].subfields[x].subfieldContent + ' ';
              }
              resourceProviders += ' | ';
            }
          } catch {}
        }
        resourceProviders = resourceProviders.replace(/ \| $/, ''); // removes final pipe
      } catch {}
      if (resourceProviders !== '') {        
        return "Access provided by " + resourceProviders;
      }
    } else {
      // Sierra item
      if (data.available) {
        if (data.material_type === 'Tool') {
          if (data.is_available_locations === 1) {
            return data.is_available_locations + " in storage";
          } else if (data.is_available_locations > 1) {
            return data.is_available_locations + " in storage";
          }
        }
        const limitedAvailability = limitedAvailabilityDetector(data)
        if (limitedAvailability.limitedlyAvailable) {
          if (data.is_available_locations === 1) {
            return data.is_copies_non_deleted + " total copies, " + data.is_holds + " holds";
          } else if (data.is_available_locations > 1) {
            return data.is_copies_non_deleted + " total copies, " + data.is_holds + " holds";
          }
        }
        if (data.is_holds > data.is_available_locations) {
          if (data.is_available_locations === 1) {
            return data.is_available_locations + " copy available, " + data.is_holds + " holds";
          } else if (data.is_available_locations > 1) {
            return data.is_available_locations + " copies available, " + data.is_holds + " holds";
          }
        } else {
          if (data.is_available_locations === 1) {
            return data.is_available_locations + " copy available now";
          } else if (data.is_available_locations > 1) {
            return data.is_available_locations + " copies available now";
          }
        }
        
      } else {
        if (data.bs_on_order) {
          let orderCount = 0;

          try {
            //orderCount = data.sm_orders.length;
            
            for (let i = 0; i < data.sm_orders.length; i++) {
              let copyCount = parseInt(data.sm_orders[i].replace(/ .+/, ''));
              orderCount += copyCount;
            }
              
          } catch {}

          if (data.is_holds > 0) {
            return data.is_holds + (data.is_holds === 1 ? ' hold, ': ' holds, ') + orderCount + (orderCount === 1 ? ' copy on order': ' copies on order');
          } else {
            return orderCount + (orderCount === 1 ? ' copy on order': ' copies on order');
          }
        } 
        if (data.is_holds > 0) {
          return data.is_holds + (data.is_holds === 1 ? ' hold, ': ' holds, ') + data.is_copies_non_deleted + (data.is_copies_non_deleted === 1 ? ' copy': ' copies');
        } else {
          return data.is_copies_non_deleted + (data.is_copies_non_deleted === 1 ? ' copy': ' copies');
        }
          
      }
    }
  } catch {
    return '';
  }
  
};

export default availabilityString;