const configs = () => {

  let subjectPill = 5;
  
  if (window.innerWidth > 1500) {
    subjectPill = 7;
  } else if (window.innerWidth > 1300) {
    subjectPill = 6;
  } else if (window.innerWidth > 1100) {
    subjectPill = 5;
  } else if (window.innerWidth > 900) {
    subjectPill = 4;
  }

  let urlDomain = '';
  if (window.location.href.indexOf('localhost') > -1) {
    urlDomain = 'https://devopac.bklynlibrary.org';
  }

  const allHomeBranchLocations = [
    {code: "01", name: "Central Library"},
    {code: "88", name: "Adams St"},
    {code: "21", name: "Arlington"},
    {code: "28", name: "Bay Ridge"},
    {code: "25", name: "Borough Park"},
    {code: "24", name: "Brighton Beach"},
    {code: "50", name: "Brooklyn Heights"},
    {code: "79", name: "Brower Park at BCM"},
    {code: "27", name: "Brownsville"},
    {code: "29", name: "Bushwick"},
    {code: "34", name: "Canarsie"},
    {code: "31", name: "Carroll Gardens"},
    {code: "91", name: "Center for Bklyn History"},
    {code: "33", name: "Clarendon"},
    {code: "83", name: "Clinton Hill"},
    {code: "32", name: "Coney Island"},
    {code: "87", name: "Cortelyou"},
    {code: "30", name: "Crown Heights"},
    {code: "65", name: "Cypress Hills"},
    {code: "35", name: "Dekalb"},
    {code: "82", name: "Dyker"},
    {code: "36", name: "East Flatbush"},
    {code: "37", name: "Eastern Parkway"},
    {code: "38", name: "Flatbush"},
    {code: "39", name: "Flatlands"},
    {code: "40", name: "Fort Hamilton"},
    {code: "66", name: "Gerritsen"},
    {code: "74", name: "Gravesend"},
    {code: "41", name: "Greenpoint"},
    {code: "42", name: "Highlawn"},
    {code: "76", name: "Homecrest"},
    {code: "81", name: "Jamaica Bay"},
    {code: "43", name: "Kensington"},
    {code: "44", name: "Kings Bay"},
    {code: "45", name: "Kings Highway"},
    {code: "58", name: "Library for Arts and Culture"},
    {code: "47", name: "Macon"},
    {code: "49", name: "Mapleton"},
    {code: "59", name: "Marcy"},
    {code: "67", name: "McKinley"},
    {code: "48", name: "Midwood"},
    {code: "68", name: "Mill Basin"},
    {code: "52", name: "New Lots"},
    {code: "51", name: "New Utrecht"},
    {code: "69", name: "Pacific"},
    {code: "78", name: "Paerdegat"},
    {code: "53", name: "Park Slope"},
    {code: "70", name: "Red Hook"},
    {code: "54", name: "Rugby"},
    {code: "57", name: "Saratoga"},
    {code: "56", name: "Sheepshead Bay"},
    {code: "85", name: "Spring Creek"},
    {code: "26", name: "Stone Ave"},
    {code: "55", name: "Sunset Park"},
    {code: "71", name: "Ulmer Park"},
    {code: "62", name: "Walt Whitman"},
    {code: "61", name: "Washington Irving"},
    {code: "60", name: "Williamsburgh"},
    {code: "77", name: "Windsor Terrace"}
  ];

  const defaultPickupLocations = [
    {code: "01", name: "Central Library"},
    {code: "88", name: "Adams St"},
    {code: "21", name: "Arlington"},
    {code: "28", name: "Bay Ridge"},
    {code: "25", name: "Borough Park"},
    {code: "24", name: "Brighton Beach"},
    {code: "50", name: "Brooklyn Heights"},
    {code: "79", name: "Brower Park at BCM"},
    {code: "27", name: "Brownsville"},
    {code: "29", name: "Bushwick"},
    {code: "34", name: "Canarsie"},
    {code: "33", name: "Clarendon"},
    {code: "83", name: "Clinton Hill"},
    {code: "32", name: "Coney Island"},
    {code: "87", name: "Cortelyou"},
    {code: "30", name: "Crown Heights"},
    {code: "65", name: "Cypress Hills"},
    //{code: "35", name: "Dekalb"},
    {code: "82", name: "Dyker"},
    {code: "36", name: "East Flatbush"},
    {code: "37", name: "Eastern Parkway"},
    {code: "38", name: "Flatbush"},
    {code: "39", name: "Flatlands"},
    {code: "40", name: "Fort Hamilton"},
    //{code: "66", name: "Gerritsen"},
    //{code: "74", name: "Gravesend"},
    {code: "41", name: "Greenpoint"},
    {code: "42", name: "Highlawn"},
    {code: "76", name: "Homecrest"},
    {code: "81", name: "Jamaica Bay"},
    {code: "43", name: "Kensington"},
    {code: "44", name: "Kings Bay"},
    {code: "45", name: "Kings Highway"},
    {code: "47", name: "Macon"},
    {code: "59", name: "Marcy"},
    {code: "67", name: "McKinley"},
    {code: "48", name: "Midwood"},
    {code: "68", name: "Mill Basin"},
    {code: "52", name: "New Lots"},
    {code: "51", name: "New Utrecht"},
    {code: "69", name: "Pacific"},
    {code: "78", name: "Paerdegat"},
    {code: "53", name: "Park Slope"},
    {code: "70", name: "Red Hook Interim"}, //interim until new branch opens
    {code: "54", name: "Rugby"},
    {code: "57", name: "Saratoga"},
    {code: "56", name: "Sheepshead Bay"},
    {code: "85", name: "Spring Creek"},
    {code: "26", name: "Stone Ave"},
    {code: "55", name: "Sunset Park"},
    {code: "71", name: "Ulmer Park"},
    //{code: "62", name: "Walt Whitman"},
    {code: "61", name: "Washington Irving"},
    {code: "60", name: "Williamsburgh"},
    {code: "77", name: "Windsor Terrace"}
  ];

  // pickup locations for services for older adults
  const soaPickupLocations = [
    {code: "90", name: "Services for Older Adults"},
    {code: "01", name: "Central Library"},
    {code: "88", name: "Adams St"},
    {code: "21", name: "Arlington"},
    {code: "28", name: "Bay Ridge"},
    {code: "25", name: "Borough Park"},
    {code: "24", name: "Brighton Beach"},
    {code: "50", name: "Brooklyn Heights"},
    {code: "79", name: "Brower Park at BCM"},
    {code: "27", name: "Brownsville"},
    {code: "29", name: "Bushwick"},
    {code: "34", name: "Canarsie"},
    {code: "83", name: "Clinton Hill"},
    {code: "32", name: "Coney Island"},
    {code: "87", name: "Cortelyou"},
    {code: "30", name: "Crown Heights"},
    {code: "65", name: "Cypress Hills"},
    //{code: "35", name: "Dekalb"},
    {code: "82", name: "Dyker"},
    {code: "36", name: "East Flatbush"},
    {code: "37", name: "Eastern Parkway"},
    {code: "38", name: "Flatbush"},
    {code: "39", name: "Flatlands"},
    {code: "40", name: "Fort Hamilton"},
    //{code: "66", name: "Gerritsen"},
    //{code: "74", name: "Gravesend"},
    {code: "41", name: "Greenpoint"},
    {code: "42", name: "Highlawn"},
    {code: "76", name: "Homecrest"},
    {code: "81", name: "Jamaica Bay"},
    {code: "43", name: "Kensington"},
    {code: "44", name: "Kings Bay"},
    {code: "45", name: "Kings Highway"},
    {code: "47", name: "Macon"},
    {code: "59", name: "Marcy"},
    {code: "67", name: "McKinley"},
    {code: "48", name: "Midwood"},
    {code: "68", name: "Mill Basin"},
    {code: "52", name: "New Lots"},
    {code: "51", name: "New Utrecht"},
    {code: "69", name: "Pacific"},
    {code: "78", name: "Paerdegat"},
    {code: "53", name: "Park Slope"},
    {code: "70", name: "Red Hook Interim"}, //interim until new branch opens
    {code: "54", name: "Rugby"},
    {code: "57", name: "Saratoga"},
    {code: "56", name: "Sheepshead Bay"},
    {code: "85", name: "Spring Creek"},
    {code: "26", name: "Stone Ave"},
    {code: "55", name: "Sunset Park"},
    {code: "71", name: "Ulmer Park"},
    //{code: "62", name: "Walt Whitman"},
    {code: "61", name: "Washington Irving"},
    {code: "60", name: "Williamsburgh"},
    {code: "77", name: "Windsor Terrace"}
  ];

  const boardGamePickupLocations = [
    {code: "01", name: "Central Library"},
    {code: "88", name: "Adams St"},
    {code: "25", name: "Borough Park"},
    {code: "27", name: "Brownsville"},
    {code: "29", name: "Bushwick"},
    {code: "30", name: "Crown Heights"},
    {code: "41", name: "Greenpoint"},
    {code: "81", name: "Jamaica Bay"},
    {code: "47", name: "Macon"},
    {code: "67", name: "McKinley"},
    {code: "51", name: "New Utrecht"},
    {code: "77", name: "Windsor Terrace"}
  ];

  const toolLibraryPickupLocations = [
    {code: "41", name: "Greenpoint"}
  ];

  const musicalInstrumentPickupLocations = [
    {code: "01", name: "Central Library"}
  ];

  const telescopePickupLocations = [
    {code: "01", name: "Central Library"}
  ];

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const monthsOfYear = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const booksUnbannedTypes = [82];

  const welcomeCardTypes = [15, 14, 13, 12];

  const staffPatonTypes = [10, 11, 24, 210];

  const defaultListDescription = 'This booklist was made for patrons by a BPL librarian. Request your own customized reading list here: https://www.bklynlibrary.org/bookmatch.';

  const configs = {
    vendorImageURL: 'https://contentcafe2.btol.com/ContentCafe/Jacket.aspx?UserID=BPL&Password=BT0059&Return=1&Type=M&Value=',
    drupalBaseURL: 'https://www.bklynlibrary.org',
    searchAPI: urlDomain + '/api/search/index.php',
    selectAPI: urlDomain + '/api/select/index.php',
    recommendAPI: urlDomain + '/api/recommend/index.php?bib=',
    baseAPI: urlDomain + "/api",
    rebalancingPtypes: [24, 25],
    digitalTypes: ["eBook", "eAudiobook"],
    subjectPillLimit: subjectPill,
    summaryLength: 300,
    defaultPageLength: 20,
    digitalCheckoutLimit: 20,
    physicalCheckoutLimit: 50,
    digitalHoldLimit: 10,
    physicalHoldLimit: 15,
    archivalCollectionCoverArt: "https://discover.bklynlibrary.org/static/box_archive1.png",
    allHomeBranchLocations:  allHomeBranchLocations,
    defaultPickupLocations: defaultPickupLocations,
    boardGamePickupLocations: boardGamePickupLocations,
    musicalInstrumentPickupLocations: musicalInstrumentPickupLocations,
    telescopePickupLocations: telescopePickupLocations,
    soaPickupLocations: soaPickupLocations,
    toolLibraryPickupLocations: toolLibraryPickupLocations,
    daysOfWeek: daysOfWeek,
    monthsOfYear: monthsOfYear,
    booksUnbannedTypes: booksUnbannedTypes,
    welcomeCardTypes: welcomeCardTypes,
    staffPatonTypes: staffPatonTypes,
    defaultListDescription: defaultListDescription,
    strichKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIzZDRlMTdhOS0wY2IzLTQxYTEtYWIzYy01Y2RiZDZlMTExNWQiLCJpc3MiOiJzdHJpY2guaW8iLCJhdWQiOlsiaHR0cHM6Ly9kaXNjb3Zlci5ia2x5bmxpYnJhcnkub3JnLyIsImh0dHBzOi8vZGV2b3BhYy5ia2x5bmxpYnJhcnkub3JnLyJdLCJpYXQiOjE3MTY5OTA1MTEsIm5iZiI6MTcxNjk5MDUxMSwiY2FwYWJpbGl0aWVzIjp7ImN1c3RvbU92ZXJsYXlMb2dvIjp0cnVlfSwidmVyc2lvbiI6MX0.uwpw0Eq29yHIAziu_31YyohlCi3gVw29V8dHOzE0oiw'
  }

  return configs;
  
};

export default configs;