import limitedAvailabilityDetector from "../../functions/limitedAvailabilityDetector"

const LimitedAvailabilityMessage = (props) => {

    let limitedlyAvailableResponse = limitedAvailabilityDetector(props.bib)

    try {
      if (limitedlyAvailableResponse.limitedlyAvailable) {
        if (limitedlyAvailableResponse.nnAvailable > 0 && limitedlyAvailableResponse.lacAvailable === 0) {
          return (
            <div className='margin-top-20'>*Available only for in-person pickup at Central Library in <a target="_blank" href="https://www.bklynlibrary.org/locations/central/dept/new-noteworthy">New & Noteworthy</a> room.</div>
          )
        }

        if (limitedlyAvailableResponse.nnAvailable > 0 && limitedlyAvailableResponse.lacAvailable > 0) {
          return (
            <div className='margin-top-20'>*{limitedlyAvailableResponse.nnAvailable} available only for in-person pickup at Central Library in <a target="_blank" href="https://www.bklynlibrary.org/locations/central/dept/new-noteworthy">New & Noteworthy</a> room and {limitedlyAvailableResponse.lacAvailable} at the <a href="https://www.bklynlibrary.org/locations/library-for-arts-and-culture">Library for Arts & Culture</a>.</div>
          )
        }

        if (limitedlyAvailableResponse.nnAvailable === 0 && limitedlyAvailableResponse.lacAvailable > 0) {
          return (
            <div className='margin-top-20'>*Available only for in-person pickup only at the <a href="https://www.bklynlibrary.org/locations/library-for-arts-and-culture">Library for Arts & Culture</a>.</div>
          )
        }
      }
    } catch {}
  }

export default LimitedAvailabilityMessage;