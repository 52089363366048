
const limitedAvailabilityDetector = (doc) => { // takes a solr doc as argument

  let response = {}

  try {

    let limitedlyAvailable = false
    let availableNewAndNoteworthyCopies = 0
    let availableLibraryForArtsAndCultureCopies = 0
    let availableOtherCopies = 0
    let otherCopies = 0

    for (let i = 0; i < doc.sm_item_data.length; i++) {
      try {
        let decodedItem = JSON.parse(doc.sm_item_data[i])
        if (decodedItem.location.code.indexOf('18nnw') > -1) { // New & Noteworthy
          if (decodedItem.status.code === '-') {
            if (typeof decodedItem.status.duedate === 'undefined') {
              availableNewAndNoteworthyCopies++
            }
          }
        } else if (decodedItem.location.code.indexOf('58') > -1) { // Library for Arts and Culture
          if (decodedItem.status.code === '-') {
            if (typeof decodedItem.status.duedate === 'undefined') {
              availableLibraryForArtsAndCultureCopies++
            }
          }
        } else {
          if (decodedItem.status.code === '-') {
            if (typeof decodedItem.status.duedate === 'undefined') {
              if (decodedItem.suppressed === false) {
                availableOtherCopies++
              }
            }
          }
          if (decodedItem.suppressed === false) {
            otherCopies++
          }
        }
      } catch {}
    }

    if (availableLibraryForArtsAndCultureCopies > 0 && availableOtherCopies === 0) {
      limitedlyAvailable = true
    }

    if (availableNewAndNoteworthyCopies > 0 && availableOtherCopies === 0) {
      limitedlyAvailable = true
    }

    if (otherCopies === 0) {
      limitedlyAvailable = false
    }

    response.limitedlyAvailable = limitedlyAvailable
    response.nnAvailable = availableNewAndNoteworthyCopies
    response.lacAvailable = availableLibraryForArtsAndCultureCopies

  } catch {}
  
  return response

}

export default limitedAvailabilityDetector