import toggleOverlay from "../../functions/toggleOverlay";
import ThinX from "../icons/thinX";
import toggleLoadingOverlay from "../../functions/toggleLoadingOverlay";
import postData from "../../functions/postData";
import configs from "../../functions/configs";
import launchErrorOverlay from "../../functions/launchErrorOverlay";
import { useContext, useState } from "react";
import { UserContext } from "../..";
import getCookie from "../../functions/getCookie";
import deleteCookie from "../../functions/deleteCookie";
import launchSuccessOverlay from "../../functions/launchSuccessOverlay";
import fallbackImageHandler from "../../functions/fallbackImageHandler";
import onLoadImageHandler from "../../functions/onLoadImageHandler";
import setCookie from "../../functions/setCookie";
import IconCircleSort from "../icons/iconCircleSort";
import getCachedFiles from "../../functions/getCachedFiles";
import deleteCachedFile from "../../functions/deleteCachedFile";

const EditListItemsOverlay = (props) => {

    const conf = configs();

    const user = useContext(UserContext);

    const url = new URL(window.location.href);

    const [activeList, setActiveList] = useState({});

    const setLoginState = props.setLoginState;
   
    const closeOverlay = (e) => {
        toggleOverlay("#edit-list-items-overlay", e);
        setActiveList({});

        try {
            getCachedFiles().then((cachedFiles)=>{
                try {
                    for (let x = 0; x < cachedFiles.length; x++) {
                        if (cachedFiles[x].indexOf('booklist=' + activeList.booklist_id) > -1) {
                            deleteCachedFile(cachedFiles[x])
                        }
                    }
                } catch {}
            });
        } catch {}

        // update interface if is booklist detail page
        if (url.pathname === '/') {
            // booklist detail page
            try {
                setTimeout(()=>{
                    url.searchParams.set('t', Date.now());
                    window.history.pushState({update: url.search}, '', url);
                    setLoginState(Date.now())
                },20)
            } catch {}
        }
    }

    const containerClick = (e) => {
        if (e.target.classList.contains('overlay-container')) {
            closeOverlay();
        }
    }

    const updateOverlayData = (e) => {
        const listId = getCookie('editListItemsId');
        deleteCookie('editListItemsId');
        postData(conf.baseAPI + "/user/return-single-list.php", 'id=' + listId).then((res)=> {
            try {
                if (typeof res.booklist_id !== 'undefined') {
                    setActiveList(res);
                } else if (typeof res.sessionExpired !== 'undefined') {
                    document.querySelector('#edit-list-items-overlay button.close-overlay').click();
                    toggleOverlay('#login-overlay', e);
                } else if (typeof res.error !== 'undefined') { // network error
                    document.querySelector('#edit-list-items-overlay button.close-overlay').click();
                    launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
                }
            } catch {
                document.querySelector('#edit-list-items-overlay button.close-overlay').click();
                launchErrorOverlay('Sorry, a network error occurred. If this issue persists, contact us.');
            }
        });
    }

    const removeItemFromList = (e) => {
        
        toggleLoadingOverlay();

        let endcodedData = "list=" + encodeURIComponent(activeList.booklist_id) + "&bib=" + encodeURIComponent(e.currentTarget.attributes.bib.value);

        postData(conf.baseAPI + "/user/remove-item-from-list.php", endcodedData).then((res)=> {
            toggleLoadingOverlay();
            if (res === null) {
                try {
                    setCookie('editListItemsId', activeList.booklist_id);
                    document.querySelector('#update-edit-list-items-overlay-data').click();
                    document.querySelector("#update-my-account-booklists-state").click();
                } catch {}
            } else if (typeof res.sessionExpired !== 'undefined') {
                document.querySelector('#edit-list-items-overlay button.close-overlay').click();
                toggleOverlay('#login-overlay', e);
            } else if (typeof res.error !== 'undefined') { // network error
                document.querySelector('#edit-list-items-overlay button.close-overlay').click();
                launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
            }
        });
    }

    
    const ListItem = (props) => {

        const [annotationState, setAnnotationState] = useState('read'); 
        const [itemState, setItemState] = useState('initial'); 

        try {
            const item = JSON.parse(props.data);

            const smallerFont = {fontSize: "14px"};

            const updateAnnotationState = (e) => {
                if (annotationState === 'read') {
                    setAnnotationState('write');
                } else {
                    // Save update
                    try {
                        document.querySelector('#small-loading-overlay').classList.remove('hide');
                    } catch {}

                    const updatedValue = document.querySelector('#edit-list-annotation-' + item.bib).value;
                    let encodedData = encodeURIComponent(updatedValue);

                    postData(conf.baseAPI + "/user/edit-item-annotation.php", "list=" + activeList.booklist_id + "&bib=" + item.bib + "&val=" + encodedData).then((res)=>{
                        
                        try {
                            document.querySelector('#small-loading-overlay').classList.add('hide');
                        } catch {}

                        if (res === null) {
                            setAnnotationState('read');
                            setCookie('editListItemsId', activeList.booklist_id);
                            document.querySelector('#update-edit-list-items-overlay-data').click();
                        } else if (typeof res.sessionExpired !== 'undefined') {
                            document.querySelector('#edit-list-items-overlay button.close-overlay').click();
                            toggleOverlay('#login-overlay', e);
                        } else if (typeof res.error !== 'undefined') { // network error
                            document.querySelector('#edit-list-items-overlay button.close-overlay').click();
                            launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
                        }

                    });
                }
            }

            const AnnotationDislay = (props) => {
                if (annotationState === 'read') {
                    if (typeof item.annotation === 'undefined') {
                        return (
                            <div className="edit-list-items-item-annotation" style={smallerFont}><em>No annotation</em></div>
                        )
                    }
                    if (item.annotation === '') {
                        return (
                            <div className="edit-list-items-item-annotation" style={smallerFont}><em>No annotation</em></div>
                        )
                    }
                    return (
                        <div className="edit-list-items-item-annotation" style={smallerFont}>{item.annotation}</div>
                    )
                }

                let textareaId = "edit-list-annotation-" + item.bib;

                if (annotationState === 'write') {
                    return (
                        <div>
                            <label className="list-item-annotation-label" for={textareaId}>Short Annotation:</label>
                            <textarea defaultValue={item.annotation} className="list-item-annotation-textarea" id={textareaId}></textarea>  
                        </div>
                    )
                }
                
            }

            const AuthorDisplay = () => {
                if (typeof item.author === 'undefined') {
                    return (
                        <div style={smallerFont} className="dark-gray edit-list-items-item-author">{item.material_type}</div>
                    )
                }

                if (item.author === '') {
                    return (
                        <div style={smallerFont} className="dark-gray edit-list-items-item-author">{item.material_type}</div>
                    )
                }

                return (
                    <div style={smallerFont} className="dark-gray edit-list-items-item-author">{item.author} - {item.material_type}</div>
                )
            }

            const EditAnnotationButton = (props) => {

                if (annotationState === 'read') {
                    return (
                        <button bib={item.bib} onClick={updateAnnotationState} className="edit-list-items-annotation-btn">Edit annotation</button>
                    )
                }

                if (annotationState === 'write') {
                    return (
                        <button bib={item.bib} onClick={updateAnnotationState} className="edit-list-items-annotation-btn">Save annotation</button>
                    )
                }
            }

            const paddingRight = {paddingRight: "10px"};

            const dragStart = (e) => {

                //e.currentTarget.classList.add('dragging');
                
                e.currentTarget.closest('.edit-list-items-item-container').classList.add('dragging');
            }

            const draggingHandler = (e) => {
                const draggedElement = document.querySelector(".dragging");
                const container = document.querySelector(".list-items-container");
                const afterElement = getDragAfterElement(container, e.clientY);
                
                const containerDimensions = container.getBoundingClientRect();
            
                if (e.clientY !== 0) {
                    if (e.clientY < containerDimensions.top) {
                        container.scrollBy({
                            top: -20,
                            left: 0,
                            behavior: "instant",
                        });
                    } else if (e.clientY > containerDimensions.bottom) {
                        container.scrollBy({
                            top: 20,
                            left: 0,
                            behavior: "instant",
                        });
                    }
                }
            }

            const dragEnd = (e) => {

                // e.currentTarget.classList.remove('dragging');
                e.currentTarget.closest('.edit-list-items-item-container').classList.remove('dragging');

                document.querySelector("#small-loading-overlay").classList.remove('hide');
                
                const listDivs = document.querySelectorAll('.edit-list-items-item-container');

                const updates = [];

                for (let i = 0; i < listDivs.length; i++) {
                    updates.push({position: i, bib: listDivs[i].attributes.bib.value});
                }

                const encodedUpdates = encodeURIComponent(JSON.stringify(updates));
                
                postData(conf.baseAPI + "/user/reorder-list-items.php", "list=" + activeList.booklist_id + "&updates=" + encodedUpdates).then((res)=>{
                    try {
                        document.querySelector('#small-loading-overlay').classList.add('hide');
                    } catch {}
                    if (res === null) {
                        setCookie('editListItemsId', activeList.booklist_id);
                        document.querySelector('#update-edit-list-items-overlay-data').click();
                        document.querySelector("#update-my-account-booklists-state").click();

                    } else if (typeof res.sessionExpired !== 'undefined') {
                        document.querySelector('#edit-list-items-overlay button.close-overlay').click();
                        toggleOverlay('#login-overlay', e);
                    } else if (typeof res.error !== 'undefined') { // network error
                        document.querySelector('#edit-list-items-overlay button.close-overlay').click();
                        launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
                    }
                });

            }

            let titleLink = '/item?b=' + item.bib;

            return (
                <div  bib={item.bib} className="edit-list-items-item-container">
                    <div className="list-sort-button-container">
                        <button onDragEnd={dragEnd} onDrag={draggingHandler} onDragStart={dragStart} draggable="true" title="Click and drag to change order" className="edit-list-items-sort-btn"><span className="sr-only">Change position</span><IconCircleSort></IconCircleSort></button>
                    </div>
                    <div>
                        <img className="edit-list-items-item-img" alt={item.title} src={item.img} onError={fallbackImageHandler} onLoad={onLoadImageHandler}></img>
                    </div>
                    <div style={paddingRight}>
                        
                        <div className="bold edit-list-items-item-title"><a target="_blank" href={titleLink}>{item.title}</a></div>

                        <AuthorDisplay></AuthorDisplay>
                        
                        <AnnotationDislay>

                        </AnnotationDislay>

                        <div className="flex-small-gap">
                            <EditAnnotationButton></EditAnnotationButton>
                            
                            <button onClick={removeItemFromList} bib={item.bib} className="edit-list-items-remove-from-list-btn">Delete item</button>
                        </div>
                        
                    </div>
                    
                </div>
            )

        } catch {}
        
    }

    const ListItems = (props)=> {
        try {
            if (activeList.items.length === 0) {
                return (
                    <div>There are no items in this list</div>
                )
            } else if (activeList.items.length > 0) {
                return activeList.items.map(item=><ListItem key={item.item_value} data={item.item_value}></ListItem>)
            }
        } catch {}
    }

    const sortDropHandler = (e) => {
        //console.log(e);
    }

    const getInnerHeight = ( elm ) => {
        var computed = getComputedStyle(elm),
            padding = parseInt(computed.paddingTop) + parseInt(computed.paddingBottom);
      
        return elm.clientHeight - padding
    }

    const dragOver = (e) => {
        const draggedElement = document.querySelector(".dragging");
        const container = document.querySelector(".list-items-container");
        const afterElement = getDragAfterElement(container, e.clientY);

        if (afterElement == undefined) {
            e.currentTarget.appendChild(draggedElement); // add to the end
        } else {
            e.currentTarget.insertBefore(draggedElement, afterElement);
        }
    }

    const getDragAfterElement = (container, y) => {
        const notDraggedCards = [...container.querySelectorAll(".edit-list-items-item-container:not(.dragging)")];
        
        return notDraggedCards.reduce((closest, child) => {
          const box = child.getBoundingClientRect()
          const offset = y - box.top - box.height / 2
          if (offset < 0 && offset > closest.offset) {
            return { offset, element: child }
          } else return closest
        }, { offset: Number.NEGATIVE_INFINITY }).element
    }

    return (
        <div onClick={containerClick} id="edit-list-items-overlay" className='overlay-container'>
            <div className='overlay-body'>
                <button aria-label="Close dialog" onClick={closeOverlay} className="close-overlay"><ThinX width='20'></ThinX></button>
                <h3>Edit List Items</h3>

                <div onDragOver={dragOver} onDrop={sortDropHandler} className="list-items-container">
                    <ListItems></ListItems>
                </div>

                <div className="flex-small-gap">
                    <button onClick={closeOverlay} id="finished-edit-list-items-btn" listid={activeList.booklist_id} className="default-blue-button">Done</button>
                    <button onClick={updateOverlayData} id="update-edit-list-items-overlay-data" className="hide"><span className="sr-only">update state</span></button>
                </div>
            </div>
        </div>
    )
}

export default EditListItemsOverlay;