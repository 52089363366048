import MagnifyingGlassIcon from '../icons/magnifyingGlassIcon';
import IconEllipsis from '../icons/iconEllipsis';
import ShareItemButton from "../shared/shareItemButton";
import {SolrContext} from '../../searchPage';
import { useContext, useState } from 'react';
import configs from '../../functions/configs';

let bindedAutocompleteListener = false;

const SearchPageHeader = (props) => { 

  const url = new URL(window.location.href);

  const conf = configs();

  const responseData = useContext(SolrContext);

  const [autocompleteData, setAutocompleteData] = useState([]);

  
  try {
    if (!bindedAutocompleteListener) {
      bindedAutocompleteListener = true;
      document.body.addEventListener('click', () => {
        try {
          if (document.activeElement.closest('#global-searchbar') === null) {
            setAutocompleteData([]);
          }
        } catch {}
      });
    }
  } catch {}

  if (url.searchParams.get('booklist') !== null) {

    try {

      const headingStyles = {
          letterSpacing: '0.02em',
          fontWeight: 'bold',
      }

      let author = '';

      if (typeof responseData.original_list.author_display_name !== 'undefined') {
        if (responseData.original_list.author_display_name !== '' && responseData.original_list.author_display_name !== null) {
          author = 'by ' + responseData.original_list.author_display_name; 
        }
      }

      const Description = (props) => {
        
        try {

          if (props.data.privacy === 'public' && props.data.type === 'staff') {
            if (props.data.description === '' || props.data.description === null) {
              props.data.description = conf.defaultListDescription;
            }
          }

          const links = props.data.description.match(/([\w+]+\:\/\/)?([\w\d-]+\.)*[\w-]+[\.\:]\w+([\/\?\=\&\#\.]?[\w-]+)*\/?/gm);

          //console.log(links);

          let text = props.data.description;

          if (links !== null) {
            for (let i = 0; i < links.length; i++) {
              let linkHref = links[i];
              if (links[i].indexOf('http://') === -1 && links[i].indexOf('https://') === -1) {
                linkHref = 'https://' + links[i];
              }
              text = text.replace(links[i], '<a target="_blank" href="' + linkHref + '">' + links[i] + '</a>');
            }
          }

          const textMarkup = text;

          const createMarkup = () => {
            return {__html: textMarkup};
          }

          return (
            <div dangerouslySetInnerHTML={createMarkup()}></div>
          )

        } catch (err) {console.log(err)}
        
      }

      try {
        document.title = responseData.original_list.title;
      } catch {}

      let booklistHeaderText = 'BOOKLIST';

      try {
        if (responseData.original_list.type === 'staff') {
          booklistHeaderText = 'BOOKLIST: STAFF SELECTIONS';
        }
      } catch {}
      
      return (
        <div className='booklist-header'>
          <div className='dark-gray' style={headingStyles}>{booklistHeaderText}</div>
          <h1 className='booklist-title'>{responseData.original_list.title}</h1>
          <h2 className='booklist-author dark-gray'>{author}</h2>
          <div className='booklist-description'>
            <Description data={responseData.original_list}></Description> 
          </div>
          <div className='booklist-share-container'>
              <ShareItemButton type="booklist" solrData={props.solrData}></ShareItemButton>
          </div>
        </div>
      )
    } catch {}

  } else {

    let placeholderText = 'Search the library';
    if (url.searchParams.get('catalog') !== null) {
      placeholderText = 'Search things we lend in any language';
    } else if (url.searchParams.get('booklistfilter') !== null) {
      placeholderText = 'Search booklists';
    } else if (url.searchParams.get('event') !== null) {
      placeholderText = 'Search events & classes';
    } else if (url.searchParams.get('website') !== null) {
      placeholderText = 'Search blogs & more';
    }

    const getAutocomplete = (e) => {
      
      if (e.currentTarget.value === '' || e.key === 'Enter') {
        setAutocompleteData([]);
        return '';
      }
      try {

        let lowerCase = e.currentTarget.value.toLowerCase();

        fetch(conf.baseAPI + '/solr/autocomplete.php?term=' + lowerCase).then(res => res.json()).then(data => {
          
          if (lowerCase === document.querySelector('#global-search-text-input').value.toLowerCase()) {
            setAutocompleteData(data);
          }
          
        });

      } catch (err) {
        
        setAutocompleteData([]);
      }
    }


    const AutoCompleteBox = (props) => {

      const leftMargin = {marginLeft: '5px'}

      const AutocompleteSelection = (props) => {
        try {

          let searchLink = "/?search=" + encodeURIComponent(props.data.term);
          if (props.data.scope === 'in Books & More') {
            searchLink = "/?search=" + encodeURIComponent(props.data.term) + '&catalog=true';
            return (
              <div className='autocomplete-search-link-container'><a searchtext={props.data.term} searchscope={props.data.scope} className='autocomplete-search-link' href={searchLink}><div><MagnifyingGlassIcon></MagnifyingGlassIcon></div><div> {props.data.term} <span style={leftMargin} className='secondary-color'> {props.data.scope}</span></div></a></div>
            )
          }
  
          if (props.data.scope === 'in Authors') {
            searchLink = "/?author=" + encodeURIComponent(props.data.term) + '&catalog=true';
            return (
              <div className='autocomplete-search-link-container'><a searchtext={props.data.term} searchscope={props.data.scope} className='autocomplete-search-link' href={searchLink}><div><MagnifyingGlassIcon></MagnifyingGlassIcon></div><div> {props.data.term} <span style={leftMargin} className='secondary-color'> {props.data.scope}</span></div></a></div>
            )
          }
  
          if (url.searchParams.get('catalog') === 'true') {
            searchLink += '&catalog=true';
          }
  
          if (url.searchParams.get('booklistfilter') === 'true') {
            searchLink += '&booklistfilter=true';
          }
  
          if (url.searchParams.get('booklistfilter') === 'true') {
            searchLink += '&booklistfilter=true';
          }
  
          if (url.searchParams.get('event') === 'true') {
            searchLink += '&event=true';
          }
  
          if (url.searchParams.get('website') === 'true') {
            searchLink += '&website=true';
          }
  
          if (url.searchParams.get('pinned') === 'checked') {
            url.searchParams.set('search', props.data.term);
            searchLink = url.search;
          }
  
          return (
            <div className='autocomplete-search-link-container'><a searchtext={props.data.term} searchscope={props.data.scope} className='autocomplete-search-link' href={searchLink}><div><MagnifyingGlassIcon></MagnifyingGlassIcon></div><div>{props.data.term}</div> </a></div>
          )

        } catch {}
        
      }

      try {

        return autocompleteData.map(item => <AutocompleteSelection key={item.term + item.scope} data={item}></AutocompleteSelection>);

      } catch {}
    }

    let searchbarContainerClasses = 'searchbar-container';

    try {
      if (autocompleteData.length > 0) {
        searchbarContainerClasses = 'searchbar-container autocomplete-active';
      }
    } catch {}
    
    return (
      <div>
        <form id="global-searchbar" onSubmitCapture={()=>{setAutocompleteData([])}} onSubmit={props.updateSearchData}>
          <div className='searchbox'>
            <div className={searchbarContainerClasses}>
              <label htmlFor='global-search-text-input' className='sr-only'>{placeholderText}</label>
              <input 
                className="global-search-input" 
                type="text" enterKeyHint='search' 
                placeholder={placeholderText} 
                autoComplete='off'
                name='search'
                id="global-search-text-input"
                onKeyUp={getAutocomplete}
                defaultValue={url.searchParams.get('search')}
                >
              </input>
              <button onClick={()=>{setAutocompleteData([])}} type='submit'><MagnifyingGlassIcon></MagnifyingGlassIcon><span className='sr-only'>Submit</span></button>
            </div>
            
            <div className='autocomplete-search-container'>
              <div className='autocomplete-content-container'>
                <AutoCompleteBox></AutoCompleteBox>
              </div>
            </div>
          </div>
          
        </form>
        
      </div>
      
    )
  }
}

export default SearchPageHeader;