import toggleOverlay from "../../functions/toggleOverlay";
import ThinX from "../icons/thinX";
import toggleLoadingOverlay from "../../functions/toggleLoadingOverlay";
import postData from "../../functions/postData";
import configs from "../../functions/configs";
import launchErrorOverlay from "../../functions/launchErrorOverlay";
import { useContext, useState } from "react";
import { UserContext } from "../..";
import getCookie from "../../functions/getCookie";
import deleteCookie from "../../functions/deleteCookie";
import IconCircleX from "../icons/iconCircleX";
import IconCircleCheck from "../icons/iconCircleCheck";
import launchSuccessOverlay from "../../functions/launchSuccessOverlay";
import getData from "../../functions/getData";
import deleteCachedFile from "../../functions/deleteCachedFile";
import getCachedFiles from "../../functions/getCachedFiles";

const EditListOverlay = (props) => {

    const conf = configs();

    const user = useContext(UserContext);

    const url = new URL(window.location.href);

    const setLoginState = props.setLoginState;

    const [activeList, setActiveList] = useState({});
    const [privacyState, setPrivacyState] = useState('private');
    const [formTitle, setFormTitle] = useState('');
    const [formDescription, setFormDescription] = useState('');
    const [isDescriptionRequired, setIsDescriptionRequired] = useState('false');
    const [formSlug, setFormSlug] = useState('');

    const closeOverlay = (e) => {
        toggleOverlay("#edit-list-overlay", e);
        setActiveList({});
    }

    const containerClick = (e) => {
        if (e.target.classList.contains('overlay-container')) {
            closeOverlay();
        }
    }

    let touchstartY = 0;
    let touchendY = 0;
    const handleTouchStart = (event) => {
        touchstartY = event.touches[0].screenY;
    }
    const handleTouchMove = (event) => {
        touchendY = event.touches[0].screenY;
    }
    const handleTouchEnd = (event) => {
        if (touchendY > (touchstartY + 300)) {
            closeOverlay();
        }
        touchstartY = 0;
        touchendY = 0;
    }

    const updateList = (e) => {

        toggleLoadingOverlay();

        try {
            if (!document.querySelector('#list-url-taken-msg').classList.contains('hide')) {
                document.querySelector('#list-url-taken-msg').classList.add('hide');
            }
        } catch {}
        
        let id = activeList.booklist_id;
        let titleData = encodeURIComponent(document.querySelector('#edit-list-overlay-name').value);
        let descriptionData = encodeURIComponent(document.querySelector('#edit-list-overlay-description').value);
        let customURL = "";
        try {
            customURL = encodeURIComponent(document.querySelector('#edit-list-custom-url').value);
        } catch {}

        let privacy = 'private';
        try {
            if (document.querySelector('#make-list-public').attributes.getNamedItem('checked') !== null) {
                privacy = 'public';
            }
        } catch {}

        document.querySelector('#list-description-required-message').classList.add('hide');
        if (privacy === 'public' && activeList.type === 'staff' && descriptionData.trim() === '') {
            document.querySelector('#list-description-required-message').classList.remove('hide');
            toggleLoadingOverlay();
            return '';
        }

        document.querySelector('#list-name-required-message').classList.add('hide');
        if (titleData.trim() === '') {
            document.querySelector('#list-name-required-message').classList.remove('hide');
            toggleLoadingOverlay();
            return '';
        }

        let postString = 'id=' + id + '&title=' + titleData + "&description=" + descriptionData + "&url=" + customURL + "&privacy=" + privacy;

        postData(conf.baseAPI + "/user/edit-list.php", postString).then((res) => {

            console.log(res);
            toggleLoadingOverlay();

            try {
                getCachedFiles().then((cachedFiles)=>{
                    try {
                        for (let x = 0; x < cachedFiles.length; x++) {
                            if (cachedFiles[x].indexOf('booklist=' + activeList.booklist_id) > -1) {
                                deleteCachedFile(cachedFiles[x])
                            }
                        }
                    } catch {}
                });
            } catch {}
                                

            if (res === null) { // success
                try {
                    launchSuccessOverlay("List updated", "");
                    document.querySelector('#edit-list-overlay button.close-overlay').click();
                    if  (url.pathname === '/my-account') {
                        try {                    
                            document.querySelector('#update-my-account-booklists-state').click();
                        } catch {}
                    } else {
                        // booklist detail page
                        try {
                            setTimeout(()=>{
                                url.searchParams.set('t', Date.now());
                                window.history.pushState({update: url.search}, '', url);
                                setLoginState(Date.now())
                            },20)
                        } catch {}
                    }
                } catch {}
            } else if (typeof res.sessionExpired !== 'undefined') {
                document.querySelector('#edit-list-overlay button.close-overlay').click();
                toggleOverlay('#login-overlay', e);
            } else if (typeof res.error !== 'undefined') { // network error
                if (res.error === 'List URL already taken') {
                    document.querySelector('#list-url-taken-msg').classList.remove('hide');
                    document.querySelector('#edit-list-custom-url').focus();
                } else {
                    document.querySelector('#edit-list-overlay button.close-overlay').click();
                    launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
                }
                
            } else {
                document.querySelector('#edit-list-overlay button.close-overlay').click();
                launchErrorOverlay(res.message, '');
            }
        });
        
    }
    


    const updateOverlayData = (e) => {
        const listId = getCookie('editListId');
        deleteCookie('editListId');
        postData(conf.baseAPI + "/user/return-single-list.php", 'id=' + listId).then((res)=> {
            try {
                if (typeof res.booklist_id !== 'undefined') {
                    setActiveList(res);
                    setFormTitle(res.title);
                    setFormSlug(res.slug);
                    setFormDescription(res.description);
                    if (res.description === '' || res.description === null) {
                        if (res.privacy === "public" && res.type === 'staff') {
                            setFormDescription(conf.defaultListDescription);
                        }
                    }
                    if (res.privacy === "public") {
                        setPrivacyState("public");
                    } else {
                        setPrivacyState("private");
                    }
                } else if (typeof res.sessionExpired !== 'undefined') {
                    document.querySelector('#edit-list-overlay button.close-overlay').click();
                    toggleOverlay('#login-overlay', e);
                } else if (typeof res.error !== 'undefined') { // network error
                    document.querySelector('#edit-list-overlay button.close-overlay').click();
                    launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
                }
            } catch {
                document.querySelector('#edit-list-overlay button.close-overlay').click();
                launchErrorOverlay('Sorry, a network error occurred. If this issue persists, contact us.');
            }
        });
    }

    const Privacy = (props) => {

        const makePrivate = () => {
            setPrivacyState('private');
        }

        const makePublic = () => {
            setPrivacyState('public');
        }

        try {

            if (privacyState === 'private') {
                return (
                    <div className="edit-list-privacy-container">
                        <div className="edit-list-public-label bold">Make public?</div>
                        <div className="dark-gray">Making this booklist public means that it can be viewed by others</div>
                        <label id="make-list-public-label" htmlFor="make-list-public"><IconCircleX height="28"></IconCircleX><span className="sr-only">Make public</span></label>
                        <input onClick={makePublic} id="make-list-public" type="checkbox"></input>
                        <div className="dark-gray">Set to <span className="bold">Private</span></div>
                    </div>
                )
            }

            if (privacyState === 'public') {
                return (
                    <div className="edit-list-privacy-container">
                        <div className="edit-list-public-label bold">Make public?</div>
                        <div className="dark-gray">Making this booklist public means that it can be viewed by others</div>
                        <label id="make-list-public-label" className="checked" htmlFor="make-list-public"><IconCircleCheck height="28"></IconCircleCheck><span className="sr-only">Make public</span></label>
                        <input onClick={makePrivate} checked="checked" id="make-list-public" type="checkbox"></input>
                        <div className="dark-gray">Set to <span className="bold">Public</span></div>
                    </div>
                )
            }

        } catch {}
    }

    const AdditionalAuthors = (props) => {
        return '';
    }

    let customUrlClasses = 'hide';

    try {
        if (user.data.isSession) {
            if (conf.staffPatonTypes.includes(user.data.session.bpl_catalog.ptype)) {
                customUrlClasses = '';
            }
        }
    } catch {}

    const checkData = (e) => {
        
        if (e.key.match(/[A-Za-z0-9\-]/)) {
            return e.key;
        }

        const allowed = ['Shift', 'Tab', 'Delete', 'Backspace'];

        if (allowed.includes(e.key)) {
            return e.key;
        }

        e.preventDefault();
        return '';
    }

    return (
        <div onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd} onClick={containerClick} id="edit-list-overlay" className='overlay-container'>
            <div className='overlay-body'>
            <button aria-label="Close dialog" onClick={closeOverlay} className="close-overlay"><ThinX width='20'></ThinX></button>
                <h3>Edit List</h3>

                <div id="list-name-required-message" className="hide form-error-message">List name is required</div>
                <label htmlFor="edit-list-overlay-name">List name</label>
                <input onChange={(e) => setFormTitle(e.currentTarget.value)} maxLength="200" id="edit-list-overlay-name" value={formTitle} type="text"></input>

                <div id="list-description-required-message" className="hide form-error-message">Description is required for public staff lists</div>
                <label htmlFor="edit-list-overlay-description">Description</label>
                <textarea onChange={(e) => setFormDescription(e.currentTarget.value)} maxLength="2000" id="edit-list-overlay-description" value={formDescription} type="text"></textarea>

                <div className={customUrlClasses}>
                    <label htmlFor="edit-list-custom-url">Custom URL</label>
                    <div id="list-url-taken-msg" className="hide secondary-color bold">Sorry, that URL is already taken. Please try another.</div>
                    <div className="dark-gray">Only letters, numbers and dashes are accepted</div>
                    <input type="text" onKeyDown={checkData} onChange={(e)=>{setFormSlug(e.target.value)}} maxLength="50" value={formSlug} id="edit-list-custom-url"></input>
                </div>

                <Privacy></Privacy>

                <AdditionalAuthors></AdditionalAuthors>

                <div className="flex-small-gap">
                    <button onClick={updateList} listid={activeList.booklist_id} className="default-blue-button">Update list</button>
                    <button onClick={closeOverlay} className="default-white-button">Cancel</button>
                    <button onClick={updateOverlayData} id="update-edit-list-overlay-data" className="hide"><span className="sr-only">update state</span></button>
                </div>
            </div>
        </div>
    )
}

export default EditListOverlay;