import limitedAvailabilityDetector from "./limitedAvailabilityDetector";

const itemStatus = (data) => {

  try {
    for (let i = 0; i < data.subjects.length; i++) {
      if (data.subjects[i] === 'BPL Zine Box') {
        //return 'Requestable by staff only';
      }
    }
  } catch {}

  if (data.bs_all_lib_use_only) {
    return 'Library Use Only';
  }
  if (data.digital_avail_type === 'AlwaysAvailable') {
    return 'Always Available';
  }
  if (data.bs_on_order) {
    return 'On Order';
  }
  if (data.available === true) {
    if (data.material_type === 'Tool') {
      return 'Place a hold';
    }
    const limitedAvailability = limitedAvailabilityDetector(data)
    if (limitedAvailability.limitedlyAvailable) {
      return 'Limited Availability'
    }
    return 'Available Now';
  } else {
    if (data.material_type === 'Music LP') {
      return '';
    }
    if (data.suppressed === true) {
      return 'Suppressed';
    }
    return 'Wait List';
  }
};

export default itemStatus;