const deleteCachedFile = (filename) => {
    try {
        caches.open('v1').then(cache => {
            // Delete the specific file
            cache.delete(filename)
            .then(deleted => {
                if (deleted) {
                console.log(filename + ' deleted from cache.');
                } else {
                console.log(filename + ' not found in cache.');
                }
            });
        });
    } catch {}
}

export default deleteCachedFile