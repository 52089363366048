const clearCache = () => {
    try {
        if (navigator.onLine) {
            caches.keys().then((keyList) =>
                Promise.all(
                keyList.map((key) => {
                    return caches.delete(key);
                }),
            ));
        }
    } catch {}
}

export default clearCache;